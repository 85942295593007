import { Context, createContext, PropsWithChildren, useCallback, useContext, useState } from 'react';

import { ContractFormTabsNames, ContractTab, contractTabsConfig } from './contractTabs';
import { UnitDetailsTabsNames } from './unitDetailsTabs';

export interface TabWithID {
  id: string | ContractFormTabsNames | UnitDetailsTabsNames;
}

export interface Options {
  [key: string]: boolean;
}

export type TabType = 'contractTabs' | 'unitDetailsTabs';

export interface TabsContextData<Tab extends TabWithID> {
  contractTabs: ContractTab[];
  changeTab: (id: Tab['id'], tabType: TabType, options: Options) => void;
  changeTabs: (id: Tab['id'][], tabType: TabType, options: Options) => void;
  resetTabs: () => void;
}

export const TabsContext = createContext<TabsContextData<TabWithID> | null>(null);

export interface TabsProviderProps<Tab extends TabWithID> {
  config?: Tab[];
}

export function TabsProvider<Tab extends TabWithID>({ children }: PropsWithChildren<TabsProviderProps<Tab>>) {
  const [contractTabs, setContractTabs] = useState([...contractTabsConfig]);

  const spreadTabs = useCallback(
    (tabType: TabType) => {
      if (tabType === 'contractTabs') {
        return [...contractTabs];
      }
    },
    [contractTabs],
  );

  const getTabsByTabType = useCallback(
    (tabType: TabType) => {
      if (tabType === 'contractTabs') {
        return contractTabs;
      }
    },
    [contractTabs],
  );

  const changeTab = useCallback(
    (id: Tab['id'], tabType, options: Options) => {
      const updatedTabs = spreadTabs(tabType);
      const idx = getTabsByTabType(tabType)!.findIndex((tab) => tab.id === id);

      if (idx === -1) {
        return;
      }

      let key: keyof typeof options;
      for (key in options) {
        //@ts-ignore
        updatedTabs[idx][key] = options[key];
      }

      if (tabType === 'contractTabs') {
        setContractTabs(updatedTabs as ContractTab[]);
      }
    },
    [getTabsByTabType, spreadTabs],
  );

  const changeTabs = useCallback(
    (ids: Tab['id'][], tabType, options: Options) => {
      const updatedTabs = spreadTabs(tabType);

      ids.forEach((id) => {
        const idx = getTabsByTabType(tabType)!.findIndex((tab) => tab.id === id);

        if (idx === -1) {
          return;
        }

        let key: keyof typeof options;
        for (key in options) {
          //@ts-ignore
          updatedTabs[idx][key] = options[key];
        }
      });

      if (tabType === 'contractTabs') {
        setContractTabs(updatedTabs as ContractTab[]);
      }
    },
    [getTabsByTabType, spreadTabs],
  );

  const resetTabs = useCallback(() => {
    const updatedTabs = [...contractTabs].map((t, idx) => {
      if (idx === 0) {
        return { ...t, isDisabled: false };
      } else {
        return { ...t, isDisabled: true };
      }
    });
    setContractTabs(updatedTabs);
  }, [contractTabs]);

  return (
    <TabsContext.Provider value={{ contractTabs, changeTab, changeTabs, resetTabs }}>{children}</TabsContext.Provider>
  );
}

export function useTabsContext<Tab extends TabWithID>() {
  const context = useContext<TabsContextData<Tab>>(TabsContext as unknown as Context<TabsContextData<Tab>>);
  if (!context) {
    throw new Error('useTabsContext must be used under TabsProvider');
  }
  return context;
}
