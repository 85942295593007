import { Button, Col, Grid, H3, Row } from '@rcl/rcl';
import { ContractStagingDTO, Park } from 'markets-lib';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { TabWithID, useTabsContext } from '../../context';
import { useRemoveMutation } from '../../store/contracts';
import { useGetByIdQuery } from '../../store/customers';
import { AddNewButton } from '../AddNewButton';
import { ContractsTable } from '../ContractsTable';
import { colSpanGeneral } from '../FormLayout';
import { HomePageImage, Message } from '../HomePageImage';
import { Page } from '../Page';
import { PageLoader } from './PageLoader';

export const ParkPage = () => {
  const history = useHistory();
  const { resetTabs } = useTabsContext<TabWithID>();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  const { data: customer, isFetching, refetch, isError } = useGetByIdQuery(id);
  const [deleteContract, { isLoading: isDeleting }] = useRemoveMutation();
  const handleDeleteContract = useCallback(
    async (id: ContractStagingDTO['id']) => {
      await deleteContract(id);
      // TODO: Figure why Tags doesn't work in RTK
      refetch();
    },
    [deleteContract, refetch],
  );

  const contracts = (customer?.contracts?.length ? customer?.contracts : []) as ContractStagingDTO[]; // TODO: Add filter by the hash
  const isReadyToBeSent = contracts.length ? contracts.every((c) => c.shares && c.shares.length) : false;

  const customerPark = customer?.park as unknown as Park;
  const contractPark = contracts[0]?.park;
  const parkType = (contractPark ?? customerPark)?.parkInformation?.parkType ?? null;
  const parkName = (contractPark ?? customerPark)?.parkInformation?.parkName ?? '';

  const handleSendTheData = () => history.push(`/${id}/confirmation`);
  const handleAddNew = () => history.push(`/${id}/contracts/add-contract`);

  useEffect(() => {
    resetTabs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isAccessOpen = customer && !isError;

  if (isFetching) {
    return <PageLoader withLayout={false} />;
  }

  return (
    <Page>
      <Grid>
        <Row>
          <Col span={12}>
            <HomePageImage parkType={parkType} isFetching={isFetching} header={parkName} text={t('parkPageMessage')} />
          </Col>
        </Row>
        {isAccessOpen ? (
          <>
            <Row>
              <Col span={colSpanGeneral}>
                <H3>{t('parkPageList')}</H3>
                <ContractsTable
                  data={contracts}
                  isLoading={isFetching || isDeleting}
                  onDeleteContract={handleDeleteContract}
                  status
                />
              </Col>
            </Row>
            <Row>
              <Col
                span={colSpanGeneral}
                css={{ display: 'flex', justifyContent: 'space-between', margin: '-0.25em 0 3em' }}
              >
                <AddNewButton isNotFixed onClick={handleAddNew} />
                <Button
                  disabled={isFetching || !isReadyToBeSent}
                  size='lg'
                  variant='secondary'
                  onClick={handleSendTheData}
                >
                  {t('sendTheData')}
                </Button>
              </Col>
            </Row>
          </>
        ) : (
          <Message header={t('linkHasExpired')} />
        )}
      </Grid>
    </Page>
  );
};
