import { useEffect, useState } from 'react';

const getLocalStorage = (key: string, defaultValue: unknown) => {
  const saved = localStorage.getItem(key);
  if (saved === null) {
    return defaultValue;
  }

  if (saved === 'true' || saved === 'false') {
    return saved === 'true';
  }

  const isObject = ['[', '{'].some((s) => s === saved[0]);
  return isObject ? JSON.parse(saved) : saved;
};

export const useLocalStorage = (key: string, defaultValue: unknown) => {
  const [value, setValue] = useState(() => getLocalStorage(key, defaultValue));

  useEffect(() => {
    const valueReady = typeof value === 'object' ? JSON.stringify(value) : value;
    localStorage.setItem(key, valueReady);
  }, [key, value]);

  return [value, setValue];
};
