import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { ContractStaging, LocationStagingDTO, SectionStagingDTO } from 'markets-lib';

import { config } from '../config';
import { prepHeaders } from '../util';

type SectionWithLocation = SectionStagingDTO & { location: LocationStagingDTO };

export const sectionsApi = createApi({
  reducerPath: 'sections',
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: config.API_URL,
    prepareHeaders: prepHeaders,
    credentials: 'include',
  }),
  tagTypes: ['Sections'],
  endpoints: (builder) => ({
    getAllParkSectionsByContractId: builder.query<SectionWithLocation[], ContractStaging['id']>({
      query: (id) => `/api/sections-staging/contract/${id}`,
      providesTags: ['Sections'],
    }),
  }),
});

export const { endpoints, middleware, reducer, reducerPath, useGetAllParkSectionsByContractIdQuery } = sectionsApi;
