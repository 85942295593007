import './i18n';

import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { globalStyles, ThemeProvider } from '@rcl/rcl';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReactReduxProvider } from 'react-redux';
import { Router as RouterRRD } from 'react-router-dom';

import { ErrorBoundary } from './components/wrappers/ErrorBoundary';
import { AuthContextProvider, TabsProvider } from './context';
import { history, reactPlugin } from './insights';
import reportWebVitals from './reportWebVitals';
import { Router } from './Router';
import { store } from './store';

ReactDOM.render(
  <StrictMode>
    <ErrorBoundary>
      <ThemeProvider css={[globalStyles, { '#root': { fill: true } }]}>
        <AppInsightsContext.Provider value={reactPlugin}>
          <ReactReduxProvider store={store}>
            <AuthContextProvider>
              <RouterRRD history={history}>
                <TabsProvider>
                  <Router />
                </TabsProvider>
              </RouterRRD>
            </AuthContextProvider>
          </ReactReduxProvider>
        </AppInsightsContext.Provider>
      </ThemeProvider>
    </ErrorBoundary>
  </StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
