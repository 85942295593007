import { Box, Col, CSS, H1, H3, Row, Text } from '@rcl/rcl';
import { ParkType } from 'markets-lib';

import anyPark from '../assets/AnyPark.png';
import solar from '../assets/Solar.png';
import windAndSolar from '../assets/WindAndSolar.png';
import windOfShore from '../assets/WindOfShore.png';
import windOnShore from '../assets/WindOnShore.png';

const textColor: CSS = { color: '$primaryAuraWhite' };

export const HomePageImage = ({
  parkType,
  isFetching,
  header,
  text,
}: {
  parkType?: ParkType | null;
  isFetching: boolean;
  header?: string;
  text?: string;
}) => {
  const [image, imageAlt] = (() => {
    switch (parkType) {
      case ParkType.Enum.WNL:
        return [windOnShore, 'Wind On Shore'];
      case ParkType.Enum.WFL:
        return [windOfShore, 'Wind Of Shore'];
      case ParkType.Enum.SOL:
        return [solar, 'Solar and Batteries'];
      case null:
        return [windAndSolar, 'Wind And Solar'];
      default:
        return [anyPark, 'Wind And Solar'];
    }
  })();

  return (
    <Box
      css={{
        borderRadius: '20px',
        height: '45vh',
        overflow: 'hidden',
        backgroundColor: '$primaryOceanBlue',

        img: {
          objectFit: 'cover',
          height: '100%',
          width: '100%',
          objectPosition: parkType === 'WFL' ? 'bottom' : parkType === 'SOL' ? 'top' : 'center',
        },
      }}
    >
      {!isFetching && <img src={image} alt={imageAlt} />}
      <Box
        css={{
          borderRadius: '20px',
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          background: !isFetching ? 'rgba(0,0,0,.3)' : 'rgba(0,0,0,0)',
        }}
      >
        <Box
          css={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            maxWidth: '500px',
            margin: '-0.75em auto',
            textAlign: 'center',
          }}
        >
          {header && <H1 css={textColor}>{header}</H1>}
          {text && (
            <Text size='$lg' css={textColor}>
              {text}
            </Text>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export const Message = ({ header, text }: { header?: string; text?: string }) => (
  <Row>
    <Col
      css={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        height: '25vh',
      }}
    >
      <H3>{header}</H3>
      <Text size='$lg'>{text}</Text>
    </Col>
  </Row>
);
