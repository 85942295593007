import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { ShareStagingDTO } from 'markets-lib';

import { config } from '../config';
import { prepHeaders } from '../util';

interface ExtraParameters {
  contractId: string;
}

export const sharesApi = createApi({
  reducerPath: 'shares',
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: config.API_URL,
    prepareHeaders: prepHeaders,
    credentials: 'include',
  }),
  tagTypes: ['Shares', 'Contracts'],
  endpoints: (builder) => ({
    upsert: builder.mutation<ShareStagingDTO & ExtraParameters, ShareStagingDTO>({
      query: (payload) => ({
        url: '/api/shares-staging',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Shares', 'Contracts'],
    }),
    getById: builder.query<ShareStagingDTO, ShareStagingDTO['id']>({
      query: (id) => `/api/shares-staging/${id}`,
      providesTags: ['Shares', 'Contracts'],
    }),
    remove: builder.mutation<ShareStagingDTO, ShareStagingDTO['id']>({
      query: (id) => ({
        url: `/api/shares-staging/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Shares', 'Contracts'],
    }),
  }),
});

export const { endpoints, middleware, reducer, reducerPath, useUpsertMutation, useRemoveMutation, useGetByIdQuery } =
  sharesApi;
