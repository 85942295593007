import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ComponentProps, ReactNode, Suspense, useContext } from 'react';
import { Redirect, Route as RouteRRD, Switch, useLocation } from 'react-router-dom';

import { CookiesWarning } from './components/CookiesWarning';
import { Layout } from './components/Layout';
import { PageLoader } from './components/pages/PageLoader';
import { AuthContext } from './context';
import { reactPlugin } from './insights';
import type { RouteInfo } from './routes';
import { routes } from './routes';

const TrackedRouteRRD = withAITracking(reactPlugin, (props: ComponentProps<typeof RouteRRD>) => {
  return <RouteRRD {...props} />;
});

const WithLayout = ({ children, withLayout }: { children: ReactNode; withLayout: boolean }) =>
  withLayout ? <Layout>{children}</Layout> : <div className='here'>{children}</div>;

// NOTE: This should be updated to include redirect login in case of code expiration, not correct code etc.
const Route = ({ component, exact, isPrivate, path, withLayout, id }: RouteInfo) => {
  const { isAuthenticated } = useContext(AuthContext);
  const location = useLocation();

  return (
    <>
      <WithLayout withLayout={withLayout}>
        {isPrivate ? (
          <>
            {isAuthenticated && <TrackedRouteRRD component={component} exact={exact} path={path} />}
            {!isAuthenticated && <Redirect to={`/welcome?path=${location.pathname}`} />}
          </>
        ) : (
          <TrackedRouteRRD component={component} exact={exact} path={path} />
        )}
      </WithLayout>
      {id !== 'error401' && <CookiesWarning />}
    </>
  );
};

export const Router = () => {
  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        {routes.map((route) => (
          <Route key={route.id} {...route} />
        ))}
      </Switch>
    </Suspense>
  );
};
