import {
  AutoPopover,
  IconFAEllipsisVertical,
  Table,
  TableColumn,
  TableDefaultCell,
  TableDefaultCellProps,
  VfPopoverMenu,
} from '@rcl/rcl';
import { t } from 'i18next';
import { ContractStagingDTO } from 'markets-lib';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

enum MenuItemIds {
  deleteContract,
}
export interface ShortContract {
  id: string;
  name: string;
  export: boolean;
}
export interface SelectedRow {
  original: ShortContract;
}

const ContractsTableCellDetails = ({
  onDeleteContract,
  value: id,
  ...props
}: TableDefaultCellProps<ContractStagingDTO> & {
  onDeleteContract?: (id: ContractStagingDTO['id']) => void;
  onAddPartner?: (id: ContractStagingDTO['id']) => void;
}) => {
  const handleSelect = useCallback(
    (itemId) => {
      if (itemId === MenuItemIds.deleteContract) {
        onDeleteContract?.(id);
      }
    },
    [id, onDeleteContract],
  );
  return (
    <TableDefaultCell alignHorizontal='right' {...props}>
      <AutoPopover component={<IconFAEllipsisVertical css={{ cursor: 'pointer' }} />} anchorPosition='right'>
        <VfPopoverMenu
          key={id}
          items={[{ id: MenuItemIds.deleteContract, label: t('contractsPage.delete') }]}
          onSelect={handleSelect}
        />
      </AutoPopover>
    </TableDefaultCell>
  );
};

export const ContractsTable = ({
  data,
  isDetailsOn = true,
  isLoading,
  isRowSelectable = false,
  noHeader = false,
  onClickContractName,
  onDeleteContract,
  onRowSelect,
  status = false,
  toggleSelectAll,
}: {
  data: ContractStagingDTO[];
  isDetailsOn?: boolean;
  isLoading?: boolean;
  isRowSelectable?: boolean;
  noHeader?: boolean;
  onClickContractName?: (id: ContractStagingDTO['id']) => void;
  onDeleteContract?: (id: ContractStagingDTO['id']) => void;
  onRowSelect?: (props: SelectedRow[]) => void;
  status?: boolean;
  toggleSelectAll?: (props: () => void) => void;
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const cssNoHeader = { margin: 0 };
  const cssProps = !noHeader
    ? { overflow: 'visible' }
    : isRowSelectable
    ? { ...cssNoHeader, 'td:first-of-type': { width: 30 } }
    : cssNoHeader;

  const contractColumns = useMemo(() => {
    const columns = [
      {
        Header: t('contractsPage.name'),
        accessor: ({ name }: ContractStagingDTO) => name,
        alignHorizontal: 'left',
        isSortable: true,
        Cell: (props: TableDefaultCellProps<ContractStagingDTO>) => (
          <TableDefaultCell
            css={{
              cursor: 'pointer',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
            onClick={
              onClickContractName
                ? () => onClickContractName(props.row.original.id)
                : () => history.push(`/${id}/contracts/${props.row.original.id}`)
            }
            title={`${props.row.original.id}`}
            {...props}
          />
        ),
      },
    ] as TableColumn<ContractStagingDTO>[];

    if (status) {
      columns.push({
        Header: 'Status',
        alignHorizontal: 'left',
        accessor: ({ id }: ContractStagingDTO) => id,
        Cell: (props: TableDefaultCellProps<ContractStagingDTO>) => (
          <TableDefaultCell alignHorizontal='left' {...props}>
            {props.row.original.shares?.length ? t('contractStatus.completed') : t('contractStatus.notCompleted')}
          </TableDefaultCell>
        ),
      });
    }

    if (isDetailsOn) {
      columns.push({
        Header: 'Details',
        alignHorizontal: 'right',
        headerHidden: true,
        accessor: ({ id }: ContractStagingDTO) => id,
        Cell: (props: TableDefaultCellProps<ContractStagingDTO>) => (
          <ContractsTableCellDetails onDeleteContract={onDeleteContract} {...props} />
        ),
      });
    }

    return columns;
  }, [t, history, isDetailsOn, onClickContractName, onDeleteContract, status, id]);

  return (
    <Table
      columns={contractColumns}
      css={{ ...cssProps, padding: '0 1em 2em 0' }}
      data={data}
      fontSize='md'
      isLoading={isLoading}
      isRowSelectable={isRowSelectable}
      noDataMessage={t('contractsPage.noContracts')}
      noHeader={noHeader}
      selectedRows={onRowSelect}
      toggleSelectAllRows={toggleSelectAll}
    />
  );
};
