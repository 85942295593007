import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, Col, Grid, Row, Text } from '@rcl/rcl';
import { GenerateAccessLink } from 'markets-lib/build';
import { useCallback, useContext } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

import { AuthContext } from '../../context';
import { useGenerateAccessTokenMutation } from '../../store/users';
import { useQuery } from '../../util';
import { ControlledInput } from '../ControlledInput';

export const LoginPage = () => {
  const query = useQuery();

  const {
    control,
    handleSubmit: reactHookFormHandleSubmit,
    formState: { isValid },
  } = useForm<GenerateAccessLink>({
    resolver: zodResolver(GenerateAccessLink),
    mode: 'onTouched',
    defaultValues: { email: query.get('email') || '' },
    reValidateMode: 'onChange',
  });

  const { isAuthenticated } = useContext(AuthContext);

  const [getAccessToken, { isError, isSuccess }] = useGenerateAccessTokenMutation();

  const { isSubmitting } = useFormState({ control });
  const { t } = useTranslation();

  const onSubmit = (data: GenerateAccessLink): void => {
    getAccessToken(data);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSubmit = useCallback(reactHookFormHandleSubmit(onSubmit), [
    getAccessToken,
    onSubmit,
    reactHookFormHandleSubmit,
  ]);

  if (isAuthenticated) {
    return <Redirect to='/home' />;
  }

  if (isError || isSuccess) {
    return (
      <div
        style={{
          height: '100vh',
          width: '100vw',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          css={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            maxWidth: '400px',
            margin: '-0.75em auto',
            textAlign: 'center',
          }}
        >
          <h3>{t('appName')}</h3>
          {isError && <p style={{ textAlign: 'left' }}> {t('loginMessageError')}</p>}
          {isSuccess && <p style={{ textAlign: 'left' }}> {t('loginMessageSuccess')}</p>}
        </Box>
      </div>
    );
  }

  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        css={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          maxWidth: '400px',
          margin: '-0.75em auto',
          textAlign: 'center',
        }}
      >
        <h3>{t('loginWelcomeMessage')}</h3>
        <p style={{ textAlign: 'left' }}>{t('loginMessage')}</p>
        <form onSubmit={handleSubmit}>
          <Grid colSpan={12} css={{ textAlign: 'left', paddingLeft: 0, paddingRight: 0 }}>
            <Row>
              <Col>
                <ControlledInput control={control} name='email' label={t('customerForm.contact.email')} />
                <Row justify='left'>
                  <Col>
                    <Text color='$tertiaryMediumDarkGray' size='$sm'>
                      {t('customerForm.multipleEmailHelper')}
                    </Text>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Grid>

          <Button variant='secondary' disabled={isSubmitting || !isValid}>
            {t('loginButtonText')}
          </Button>
        </form>
      </Box>
    </div>
  );
};
