import { AppShell, IconAssortment, IconUser, Navbar } from '@rcl/rcl';
import { uuid } from 'markets-lib';
import { createElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { routes } from '../routes';

export const Layout = ({ children }: { children: ReactNode }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  // NOTE: useParams() doesn't work here
  let stagingAggregatorId = '';
  try {
    stagingAggregatorId = uuid.parse(pathname.slice(1, 37));
  } catch (e) {
    stagingAggregatorId = '';
  }

  return (
    <AppShell
      headerWidgets={<IconUser css={{ cursor: 'pointer' }} />}
      navbarItems={
        <>
          {routes
            .filter(({ hideInNav, id }) => {
              if (!stagingAggregatorId && id === 'contracts') {
                return false;
              }

              return !hideInNav;
            })
            .map(({ icon, id, name, path }) => (
              <Navbar.Item
                key={id}
                icon={icon ? createElement(icon) : <IconAssortment />}
                onClick={() => {
                  history.push(path.replace(':id', stagingAggregatorId));
                }}
                selected={history.location.pathname === path.replace(':id', stagingAggregatorId)}
                title={t(name)}
              />
            ))}
        </>
      }
    >
      {children}
    </AppShell>
  );
};
