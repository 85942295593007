import { Col, ColSpan, CSS, Grid, H3, PropertyValue, Row, Stack, Text, ValueByBreakpoint } from '@rcl/rcl';
import { ReactNode } from 'react';

export const colSpanGeneral = { xs: 11, md: 10, lg: 6 } as ValueByBreakpoint<ColSpan>;
export const colSpanForm = { xs: 12, xl: 10 } as ValueByBreakpoint<ColSpan>;
export const colSpanTwoCol = { xs: 12, md: 12, lg: 6 } as ValueByBreakpoint<ColSpan>;

export const FormElement = ({ children, comment, css }: { children: ReactNode; comment?: string; css?: CSS }) => (
  <Row css={css}>
    <Col span={{ xs: 12 }}>
      {comment ? (
        <Stack axis='y' gap='10px'>
          {children}
          <Text color='$tertiaryMediumDarkGray' size='$sm'>
            {comment}
          </Text>
        </Stack>
      ) : (
        children
      )}
    </Col>
  </Row>
);

export const FormColumn = ({
  children,
  span,
  grow,
}: {
  children: ReactNode;
  grow?: boolean;
  span?: ValueByBreakpoint<ColSpan>;
}) => (
  <Col span={span} grow={grow}>
    <Grid padding='0px' rowGap='15px'>
      {children}
    </Grid>
  </Col>
);

export const FormLayout = ({
  cancel,
  children,
  css,
  error,
  rowGap,
  submit,
}: {
  cancel?: ReactNode;
  children: ReactNode;
  css?: CSS;
  error?: string;
  rowGap?: PropertyValue<'gap'>;
  submit: ReactNode;
}) => {
  return (
    <Grid rowGap={rowGap} css={css}>
      <Row>
        <FormColumn span={colSpanForm}>
          {children}

          {error && (
            <Row>
              <Col css={{ centerY: true }} span={{ xs: 12, lg: 12, xl: 8 }}>
                <Text size='$lg' color='$secondaryRed'>
                  {error}
                </Text>
              </Col>
            </Row>
          )}

          <Row align='center' colSpan={undefined} css={{ paddingY: '40px' }} justify='flex-end'>
            <Col css={{ centerY: true }}>{cancel}</Col>
            <Col css={{ centerY: true }}>{submit}</Col>
          </Row>
        </FormColumn>
      </Row>
    </Grid>
  );
};

export const FormSection = ({
  title,
  children,
  titleCss,
}: {
  children: ReactNode;
  title?: string | ReactNode;
  titleCss?: CSS;
}) => (
  <>
    {title && (
      <FormElement>
        <H3 css={{ margin: '2rem 0 1rem', ...titleCss }}>{title}</H3>
      </FormElement>
    )}
    {children}
  </>
);

export const FormTwoColumnRow = ({
  columnLeft,
  columnRight,
  commentLeft,
  commentRight,
  cssColumnLeft,
  cssColumnRight,
  cssRow,
  span = colSpanTwoCol,
}: {
  columnLeft?: ReactNode;
  columnRight?: ReactNode;
  commentLeft?: string;
  commentRight?: string;
  cssColumnLeft?: CSS;
  cssColumnRight?: CSS;
  cssRow?: CSS;
  span?: ValueByBreakpoint<ColSpan>;
}) => (
  <Row className='row' css={cssRow}>
    <Col span={span ?? 6} css={cssColumnLeft}>
      <FormElement comment={commentLeft}>{columnLeft}</FormElement>
    </Col>

    <Col span={span ?? 6} css={cssColumnRight}>
      <FormElement comment={commentRight}>{columnRight}</FormElement>
    </Col>
  </Row>
);
