import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { ParkStaging } from 'markets-lib';

import { config } from '../config';
import { prepHeaders } from '../util';

interface ExtraParameters {
  contractId: string;
}

export const parksApi = createApi({
  reducerPath: 'parks',
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: config.API_URL,
    prepareHeaders: prepHeaders,
    credentials: 'include',
  }),
  tagTypes: ['Parks'],
  endpoints: (builder) => ({
    upsert: builder.mutation<ParkStaging & ExtraParameters, ParkStaging>({
      query: (payload) => ({
        url: '/api/parks-staging',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Parks'],
    }),
    getAll: builder.query<ParkStaging[], void>({
      query: () => '/api/parks-staging',
      providesTags: ['Parks'],
    }),
    getById: builder.query<ParkStaging, ParkStaging['id']>({
      query: (id) => `/api/parks-staging/${id}`,
      providesTags: ['Parks'],
    }),
    remove: builder.mutation<ParkStaging[], ParkStaging['id']>({
      query: (id) => ({
        url: `/api/parks-staging/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Parks'],
    }),
  }),
});

export const {
  endpoints,
  middleware,
  reducer,
  reducerPath,
  useUpsertMutation,
  useRemoveMutation,
  useGetAllQuery,
  useGetByIdQuery,
} = parksApi;
