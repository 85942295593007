import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { billingsApi } from './billings';
import { contractsApi } from './contracts';
import { customersApi } from './customers';
import { listsDataApi } from './listsData';
import { locationsApi } from './locations';
import { parksApi } from './parks';
import { sectionsApi } from './sections';
import { sharesApi } from './shares';
import { stagingAggregatorApi } from './stagingAggregators';
import { turbinesApi } from './turbines';
import { userApi } from './users';

export const store = configureStore({
  reducer: {
    [billingsApi.reducerPath]: billingsApi.reducer,
    [contractsApi.reducerPath]: contractsApi.reducer,
    [customersApi.reducerPath]: customersApi.reducer,
    [listsDataApi.reducerPath]: listsDataApi.reducer,
    [locationsApi.reducerPath]: locationsApi.reducer,
    [parksApi.reducerPath]: parksApi.reducer,
    [sectionsApi.reducerPath]: sectionsApi.reducer,
    [sharesApi.reducerPath]: sharesApi.reducer,
    [stagingAggregatorApi.reducerPath]: stagingAggregatorApi.reducer,
    [turbinesApi.reducerPath]: turbinesApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      billingsApi.middleware,
      contractsApi.middleware,
      customersApi.middleware,
      listsDataApi.middleware,
      locationsApi.middleware,
      parksApi.middleware,
      sectionsApi.middleware,
      sharesApi.middleware,
      stagingAggregatorApi.middleware,
      turbinesApi.middleware,
      userApi.middleware,
    ),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
