import { Box, CSS, IconCold, Input, Text } from '@rcl/rcl';
import { ChangeEvent, HTMLInputTypeAttribute, useMemo, WheelEvent } from 'react';
import { FieldValues, useController, UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const ControlledInput = <T extends FieldValues>({
  control,
  css,
  dontChangeType = false,
  formatValue,
  isDisabled,
  label,
  max,
  min,
  name,
  step,
  type,
  isMarked,
  setNullOnEmpty,
  tVariable,
}: UseControllerProps<T> & {
  css?: CSS;
  dontChangeType?: boolean;
  type?: HTMLInputTypeAttribute;
  formatValue?: (value: string | number) => string | number;
  isDisabled?: boolean;
  isMarked?: boolean;
  label?: string;
  min?: number;
  max?: number;
  step?: number;
  setNullOnEmpty?: boolean;
  tVariable?: {};
}) => {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error },
    formState: { isSubmitting },
  } = useController({
    control,
    name,
  });

  const { t } = useTranslation();

  const eMessage = useMemo(() => {
    if (error?.type === 'invalid_type' && error.message === 'Required') {
      return t('customerForm.errors.empty');
    }
    if (error?.type === 'invalid_type' && type === 'number' && error.message === 'Expected number, received null') {
      return t('customerForm.errors.empty');
    }
    if (error?.type === 'invalid_type' && type === 'number' && error.message === 'Expected number, received string') {
      return t('customerForm.errors.empty');
    }
    return error?.message;
  }, [error, type, t]);

  // https://react-hook-form.com/api/usecontroller
  const formatOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (type === 'number' && !dontChangeType) {
      if (!e.target.value) {
        onChange(null);
      } else {
        onChange(Number(e.target.value));
      }
    } else {
      onChange(e.target.value);
    }

    if (!e.target.value && setNullOnEmpty) {
      onChange(null);
    }
  };

  return (
    <Box css={{ position: 'relative' }}>
      <Input
        css={css}
        disabled={isSubmitting || isDisabled}
        error={!!error}
        fontSize='md'
        label={label}
        name={name}
        step={step}
        min={min}
        max={max}
        onBlur={onBlur}
        onWheel={(e: WheelEvent<HTMLInputElement>) => (e.target as HTMLElement).blur()}
        onChange={formatOnChange}
        ref={ref}
        type={type}
        value={formatValue ? formatValue(value || '') : value || ''}
        variant='underline'
      />
      {eMessage && <Text color='$secondaryRed'>{t(eMessage, tVariable)}</Text>}
      {isMarked && (
        <IconCold
          css={{
            position: 'absolute',
            width: '0.75em',
            top: '-4px',
            right: '0.2em',
            fill: '$secondary',
          }}
        />
      )}
    </Box>
  );
};
