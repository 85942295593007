import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { ActiveDirectoryUser, GenerateAccessLink, User } from 'markets-lib';

import { config } from '../config';
import { prepGraphHeaders, prepHeaders } from '../util';

export const userApi = createApi({
  reducerPath: 'users',
  baseQuery: fetchBaseQuery({
    baseUrl: config.API_URL,
    prepareHeaders: prepHeaders,
    credentials: 'include',
  }),
  tagTypes: ['Users'],
  endpoints: (builder) => ({
    getMe: builder.query<User, void>({
      query: () => '/api/me',
      providesTags: ['Users'],
    }),
    generateAccessToken: builder.mutation<string, GenerateAccessLink>({
      query: (payload) => ({
        url: '/api/auth/request-token',
        method: 'POST',
        body: payload,
      }),
    }),
    getExternalAuth: builder.mutation<User, void>({
      query: () => '/api/me-external-auth',
    }),
  }),
});

export const {
  endpoints,
  middleware,
  reducer,
  reducerPath,
  useGetMeQuery,
  useGenerateAccessTokenMutation,
  useGetExternalAuthMutation,
} = userApi;

export const graphApi = createApi({
  reducerPath: 'graph',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://graph.microsoft.com/v1.0',
    prepareHeaders: prepGraphHeaders,
  }),
  tagTypes: ['Graph'],
  endpoints: (builder) => ({
    // NOTE: search based on following query
    // https://graph.microsoft.com/v1.0/users?$search="displayName:szymon" OR "mail:szymon"&$filter=givenName ne null and surname ne null and mail ne null&$select=id,displayName,mail,givenName,surname
    getActiveDirectoryUsers: builder.query<ActiveDirectoryUser[], string>({
      query: (query) =>
        `/users?$search="displayName:${query}" OR "mail:${query}"&$filter=givenName ne null and surname ne null and mail ne null&$select=id,displayName,givenName,surname`,
      providesTags: ['Graph'],
    }),
  }),
});

export const { useGetActiveDirectoryUsersQuery } = graphApi;
