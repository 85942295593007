import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { StagingAggregatorDTO } from 'markets-lib';

import { config } from '../config';
import { prepHeaders } from '../util';

export const customersApi = createApi({
  reducerPath: 'customers',
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: config.API_URL,
    prepareHeaders: prepHeaders,
    credentials: 'include',
  }),
  tagTypes: ['Customers'],
  endpoints: (builder) => ({
    getList: builder.query<StagingAggregatorDTO[], null>({
      query: () => ({
        url: '/api/staging-aggregators/external',
        method: 'GET',
      }),
    }),
    getById: builder.query<StagingAggregatorDTO, StagingAggregatorDTO['id']>({
      query: (id) => `/api/staging-aggregators/${id}`,
      providesTags: ['Customers'],
    }),
    updateMailingList: builder.mutation<
      StagingAggregatorDTO,
      Omit<StagingAggregatorDTO, 'name' | 'park' | 'contracts' | 'isSubmitted'>
    >({
      query: (contract) => ({
        url: `/api/staging-aggregators/${contract.id}/update-mailing-list`,
        method: 'POST',
        body: contract,
      }),
      invalidatesTags: ['Customers'],
    }),
  }),
});

export const {
  endpoints,
  middleware,
  reducer,
  reducerPath,
  useGetByIdQuery,
  useUpdateMailingListMutation,
  useGetListQuery,
} = customersApi;
