import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
//TODO: Update to ParkStaging
import type { ContractStaging, LocationStagingDTO, TurbineStagingDTO } from 'markets-lib';

import { config } from '../config';
import { prepHeaders } from '../util';

type TurbineWithLocation = TurbineStagingDTO & { location: LocationStagingDTO };

export const turbinesApi = createApi({
  reducerPath: 'turbines',
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: config.API_URL,
    prepareHeaders: prepHeaders,
    credentials: 'include',
  }),
  tagTypes: ['Turbines'],
  endpoints: (builder) => ({
    getAllParkTurbinesByContractId: builder.query<TurbineWithLocation[], ContractStaging['id']>({
      query: (id) => `/api/turbines-staging/contract/${id}`,
      providesTags: ['Turbines'],
    }),
  }),
});

export const { endpoints, middleware, reducer, reducerPath, useGetAllParkTurbinesByContractIdQuery } = turbinesApi;
