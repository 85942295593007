import { IconContractDetails, IconHouse, IconListView, IconSolarPanel } from '@rcl/rcl';
import { ComponentType, lazy } from 'react';

import { ErrorPage } from './components/pages/ErrorPage';
import { HomePage } from './components/pages/HomePage';
import { LoginPage } from './components/pages/LoginPage';
import { ParkPage } from './components/pages/ParkPage';
import { WelcomePage } from './components/pages/WelcomePage';

const AddContract = lazy(() =>import('./components/pages/contracts/AddContract').then(({ AddContract }) => ({ default: AddContract }))) // prettier-ignore
const EditContract = lazy(() =>import('./components/pages/contracts/EditContract').then(({ EditContract }) => ({ default: EditContract }))) // prettier-ignore
const UpsertBilling = lazy(() => import('./components/pages/contracts/UpsertBilling').then(({ UpsertBilling }) => ({ default: UpsertBilling }))) // prettier-ignore
const AddSection = lazy(() => import('./components/pages/contracts/AddSection').then(({ AddSection }) => ({ default: AddSection }))) // prettier-ignore
const AddTurbine = lazy(() => import('./components/pages/contracts/AddTurbine').then(({ AddTurbine }) => ({ default: AddTurbine }))) // prettier-ignore
const EditSection = lazy(() => import('./components/pages/contracts/EditSection').then(({ EditSection }) => ({ default: EditSection }))) // prettier-ignore
const EditTurbine = lazy(() => import('./components/pages/contracts/EditTurbine').then(({ EditTurbine }) => ({ default: EditTurbine }))) // prettier-ignore

const SharesList = lazy(() => import('./components/pages/contracts/SharesList').then(({ SharesList }) => ({ default: SharesList }))) // prettier-ignore
const UpsertPark = lazy(() => import('./components/pages/contracts/UpsertPark').then(({ UpsertPark }) => ({ default: UpsertPark }))) // prettier-ignore
const ConfirmationPage = lazy(() => import('./components/pages/ConfirmationPage').then(({ ConfirmationPage }) => ({ default: ConfirmationPage }))) // prettier-ignore

export interface RouteInfo {
  component: ComponentType;
  exact: boolean;
  hideInNav: boolean;
  icon?: ComponentType;
  id: string;
  isPrivate: boolean;
  name: string; // page name declared in locales/${translation}.json
  needsAdmin: boolean;
  path: string;
  withLayout: boolean;
}

export const routes: RouteInfo[] = [
  // #region Basic navigation
  {
    component: WelcomePage,
    exact: true,
    hideInNav: true,
    id: 'welcome-redirect',
    name: 'Welcome',
    needsAdmin: false,
    isPrivate: false,
    path: '/',
    withLayout: false,
  },
  {
    component: WelcomePage,
    exact: true,
    hideInNav: true,
    id: 'welcome',
    name: 'Welcome',
    needsAdmin: false,
    isPrivate: false,
    path: '/welcome',
    withLayout: false,
  },
  {
    component: LoginPage,
    exact: false,
    hideInNav: true,
    id: 'login',
    name: 'Login',
    needsAdmin: false,
    isPrivate: false,
    path: '/login',
    withLayout: false,
  },
  {
    component: ErrorPage,
    exact: true,
    hideInNav: true,
    id: 'error401',
    name: 'pages.error401',
    needsAdmin: true,
    isPrivate: false,
    path: '/401',
    withLayout: true,
  },
  {
    component: HomePage,
    exact: true,
    hideInNav: false,
    id: 'home',
    icon: IconHouse,
    name: 'pages.home',
    needsAdmin: false,
    isPrivate: true,
    path: '/home',
    withLayout: true,
  },
  {
    component: HomePage,
    exact: true,
    hideInNav: true,
    id: 'home-share-park',
    icon: IconHouse,
    name: 'pages.home',
    needsAdmin: false,
    isPrivate: true,
    path: '/home/share-park/:id',
    withLayout: true,
  },
  {
    component: ParkPage,
    exact: true,
    hideInNav: false,
    id: 'contracts',
    icon: IconListView,
    name: 'pages.contracts',
    needsAdmin: false,
    isPrivate: true,
    path: '/:id/contracts',
    withLayout: true,
  },
  {
    component: AddContract,
    exact: true,
    hideInNav: true,
    id: 'add-contract',
    name: 'pages.add-contract',
    needsAdmin: true,
    isPrivate: true,
    path: '/:id/contracts/add-contract',
    withLayout: true,
    icon: IconContractDetails,
  },
  {
    component: EditContract,
    exact: true,
    hideInNav: true,
    id: 'edit-contract',
    name: 'pages.edit-contract',
    needsAdmin: true,
    isPrivate: true,
    path: '/:id/contracts/:contractId',
    withLayout: true,
    icon: IconContractDetails,
  },
  {
    component: UpsertBilling,
    exact: true,
    hideInNav: true,
    id: 'edit-billing',
    name: 'pages.edit-billing',
    needsAdmin: true,
    isPrivate: true,
    path: '/:id/contracts/:contractId/billing',
    withLayout: true,
  },
  {
    component: UpsertPark,
    exact: true,
    hideInNav: true,
    id: 'edit-park',
    name: 'pages.edit-park',
    needsAdmin: false,
    isPrivate: true,
    path: '/:id/contracts/:contractId/park',
    withLayout: true,
    icon: IconSolarPanel,
  },
  {
    component: SharesList,
    exact: true,
    hideInNav: true,
    id: 'shares-list',
    name: 'pages.shares-list',
    needsAdmin: false,
    isPrivate: true,
    path: '/:id/contracts/:contractId/shares',
    withLayout: true,
  },
  {
    component: AddSection,
    exact: true,
    hideInNav: true,
    id: 'add-section',
    name: 'pages.add-section',
    needsAdmin: false,
    isPrivate: true,
    path: '/:id/contracts/:contractId/add-section',
    withLayout: true,
  },
  {
    component: EditSection,
    exact: true,
    hideInNav: true,
    id: 'edit-section',
    name: 'pages.edit-section',
    needsAdmin: false,
    isPrivate: true,
    path: '/:id/contracts/:contractId/section/:shareId',
    withLayout: true,
  },
  {
    component: AddTurbine,
    exact: true,
    hideInNav: true,
    id: 'add-turbine',
    name: 'pages.add-turbine',
    needsAdmin: false,
    isPrivate: true,
    path: '/:id/contracts/:contractId/add-turbine',
    withLayout: true,
  },
  {
    component: EditTurbine,
    exact: true,
    hideInNav: true,
    id: 'edit-turbine',
    name: 'pages.edit-turbine',
    needsAdmin: false,
    isPrivate: true,
    path: '/:id/contracts/:contractId/turbine/:shareId',
    withLayout: true,
  },
  {
    component: ConfirmationPage,
    exact: true,
    hideInNav: true,
    id: 'confirmation',
    name: 'pages.confirmation',
    needsAdmin: true,
    isPrivate: true,
    path: '/:id/confirmation',
    withLayout: true,
  },
  // #endregion
];
