import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Col, Grid, H3, Modal, ModalContent, ModalFooter, ModalHeader, Row, Spinner, Text } from '@rcl/rcl';
import { UpdateMailingListDTO } from 'markets-lib';
import { FC, useCallback } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useGetByIdQuery, useUpdateMailingListMutation } from '../store/customers';
import { copyAndSetObjectValues, shouldPrefillForm } from '../util';
import { ControlledInput } from './ControlledInput';

interface IPartnersFormModal {
  handleClose: () => void;
  isOpen: boolean;
}
export const mockedPartnerForm: UpdateMailingListDTO = {
  sharedWith: 'a@test.com',
};

const emptyInitialData = copyAndSetObjectValues(mockedPartnerForm) as unknown as UpdateMailingListDTO;

const ShareParkModal: FC<IPartnersFormModal> = ({ handleClose, isOpen }) => {
  const { id } = useParams<{ id: string }>();

  const { t } = useTranslation();
  const [sharePark] = useUpdateMailingListMutation();
  const { data: currentAggregator, isFetching } = useGetByIdQuery(id);
  const {
    control,
    handleSubmit: reactHookFormHandleSubmit,
    reset,
    formState: { isValid },
  } = useForm<UpdateMailingListDTO>({
    defaultValues: shouldPrefillForm ? mockedPartnerForm : emptyInitialData,
    resolver: zodResolver(UpdateMailingListDTO),
    mode: 'onTouched',
    reValidateMode: 'onChange',
  });

  const { isSubmitting } = useFormState({ control });

  const closeAndReset = () => {
    handleClose();
    reset();
  };

  const onSubmit = (data: UpdateMailingListDTO): void => {
    sharePark({ ...data, id });
    handleClose();
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSubmit = useCallback(reactHookFormHandleSubmit(onSubmit), [
    sharePark,
    onSubmit,
    reactHookFormHandleSubmit,
  ]);

  return (
    <Modal onOutsideClick={() => {}} show={isOpen}>
      {!isFetching && (
        <>
          <ModalHeader>
            <H3 css={{ marginTop: 0, marginBottom: 0, textAlign: 'left' }}>{`${t('contractsPage.share')}: ${
              currentAggregator?.name
            }`}</H3>
          </ModalHeader>
        </>
      )}

      <form onSubmit={handleSubmit}>
        <ModalContent css={{ alignItems: 'initial', minWidth: '450px' }}>
          {!isFetching && (
            <Grid colSpan={12}>
              <Row>
                <Col>
                  <ControlledInput control={control} name='sharedWith' label={t('customerForm.contact.email')} />
                  <Row justify='left'>
                    <Col>
                      <Text color='$tertiaryMediumDarkGray' size='$sm'>
                        {t('customerForm.multipleEmailHelper')}
                      </Text>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Grid>
          )}
          {isFetching && <Spinner />}
        </ModalContent>

        <ModalFooter css={{ marginLeft: 0, padding: '0 36px 36px' }}>
          <Button variant='tertiaryBlack' onClick={closeAndReset}>
            {t('forms.cancel')}
          </Button>
          <Button variant='secondary' disabled={isSubmitting || !isValid}>
            {t('forms.submit')}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export { ShareParkModal };
