import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { AuthContext } from '../../context';
import { useGetExternalAuthMutation } from '../../store/users';
import { useQuery } from '../../util';

export const WelcomePage = () => {
  const history = useHistory();
  const query = useQuery();

  const { authenticate, signOut } = useContext(AuthContext);

  const [authorize] = useGetExternalAuthMutation();

  useEffect(() => {
    authorize()
      .unwrap()
      .then(() => {
        authenticate();
        history.push(query?.get('path') || '/home');
      })
      .catch((err) => {
        console.error(err);
        signOut();
        history.push('/401');
      });
  }, [authenticate, authorize, signOut, history, query]);

  return null;
};
