import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { DSOListModel, ListModelType, TSOListModel } from 'markets-lib';

import { config } from '../config';
import { prepHeaders } from '../util';

const BASE_PATH = '/api/listsData';

export const listsDataApi = createApi({
  reducerPath: 'listsData',
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: config.API_URL,
    prepareHeaders: prepHeaders,
    credentials: 'include',
  }),
  tagTypes: ['ListsData'],
  endpoints: (builder) => ({
    getDSOs: builder.query<DSOListModel[], void>({
      query: () => `${BASE_PATH}?type=${ListModelType.enum.DSO}`,
      providesTags: ['ListsData'],
    }),
    getTSOs: builder.query<TSOListModel[], void>({
      query: () => `${BASE_PATH}?type=${ListModelType.enum.TSO}`,
      providesTags: ['ListsData'],
    }),
  }),
});

export const { endpoints, middleware, reducer, reducerPath, useGetDSOsQuery, useGetTSOsQuery } = listsDataApi;
