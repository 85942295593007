import { Button, IconMore } from '@rcl/rcl';

export const AddNewButton = ({ onClick, isNotFixed = false }: { onClick: () => void; isNotFixed?: boolean }) => {
  return (
    <Button
      variant='secondary'
      arrow={false}
      css={{
        borderRadius: '50%',
        padding: '1em',
        minWidth: '0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: isNotFixed ? 'relative' : 'fixed',
        bottom: isNotFixed ? '0' : '0.5em',
        right: isNotFixed ? '0' : '0.5em',
      }}
      onClick={onClick}
    >
      <IconMore css={{ fill: '$background' }} />
    </Button>
  );
};
