import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { StagingAggregatorDTO } from 'markets-lib';

import { config } from '../config';
import { prepHeaders } from '../util';

export const stagingAggregatorApi = createApi({
  reducerPath: 'stagingAggregators',
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: config.API_URL,
    prepareHeaders: prepHeaders,
    credentials: 'include',
  }),
  tagTypes: ['StagingAggregators'],
  endpoints: (builder) => ({
    submitStagingAggregator: builder.mutation<StagingAggregatorDTO, StagingAggregatorDTO['id']>({
      query: (id) => ({
        url: `/api/staging-aggregators/${id}/submit`,
        method: 'POST',
      }),
      invalidatesTags: ['StagingAggregators'],
    }),
  }),
});

export const { endpoints, middleware, reducer, reducerPath, useSubmitStagingAggregatorMutation } = stagingAggregatorApi;
