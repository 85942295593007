import { Box, Button } from '@rcl/rcl';
import { useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import { useLocalStorage } from '../hooks/useLocalStorage';

const buttonTransparentCss = {
  border: 'none',
  backgroundColor: 'transparent',
  color: '$primary',
  cursor: 'pointer',
  fontSize: 'inherit',
  padding: 0,
  '&:hover': { color: '$primaryAuraWhite' },
};

export const CookiesWarning = () => {
  const [isCookiesConsentOpen, setIsCookiesConsentOpen] = useLocalStorage('isCookiesConsentOpen', true);
  const [isLearnMoreOpen, toggleLearnMore] = useReducer((state) => !state, false);
  const { t } = useTranslation();

  const handleClose = () => setIsCookiesConsentOpen(false);

  if (!isCookiesConsentOpen) {
    return null;
  }

  return (
    <Box
      css={{
        backgroundColor: '$secondaryDarkBlue',
        bottom: 0,
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '1.2em 2em',
        position: 'fixed',
        width: '100%',
        zIndex: 999,
        p: {
          color: '$primaryAuraWhite',
        },
      }}
    >
      <Box>
        <p>
          {t('cookiesConsent.weUseCookies')}
          <Box
            as='button'
            onClick={toggleLearnMore}
            css={{ ...buttonTransparentCss, textDecoration: 'underline', textUnderlineOffset: '0.18em' }}
          >
            {isLearnMoreOpen ? t('cookiesConsent.showLess') : t('cookiesConsent.learnMore')}
          </Box>
        </p>
        {isLearnMoreOpen && (
          <>
            <p>{t('cookiesConsent.learnMoreText')}</p>
            <p>{t('cookiesConsent.learnMoreText2')}</p>
          </>
        )}
      </Box>
      <Box
        css={{
          display: 'flex',
          alignItems: 'center',
          paddingLeft: '2em',
        }}
      >
        <Button arrow={false} onClick={handleClose}>
          {t('cookiesConsent.accept')}
        </Button>
      </Box>
    </Box>
  );
};
