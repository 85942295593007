export enum UnitDetailsTabsNames {
  UNIT_DETAILS,
  EMSYS,
}

export interface UnitDetailsTab {
  id: UnitDetailsTabsNames;
  isDisabled: boolean;
}

export const unitDetailsTabsConfig: UnitDetailsTab[] = [
  {
    id: UnitDetailsTabsNames.UNIT_DETAILS,
    isDisabled: false,
  },
  {
    id: UnitDetailsTabsNames.EMSYS,
    isDisabled: true,
  },
];
