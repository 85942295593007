import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { Contract, LocationDTO } from 'markets-lib';

import { config } from '../config';
import { prepHeaders } from '../util';

export const locationsApi = createApi({
  reducerPath: 'locations',
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: config.API_URL,
    prepareHeaders: prepHeaders,
    credentials: 'include',
  }),
  tagTypes: ['Locations'],
  endpoints: (builder) => ({
    getParkLocationsByContractId: builder.query<LocationDTO[], Contract['id']>({
      query: (id) => `/api/locations-staging/contract/${id}`,
      providesTags: ['Locations'],
    }),
  }),
});

export const {
  endpoints,
  middleware,
  reducer,
  reducerPath,
  useGetParkLocationsByContractIdQuery,
  useLazyGetParkLocationsByContractIdQuery,
} = locationsApi;
