import { createContext, FC, ReactNode, useEffect, useState } from 'react';

import { useGetExternalAuthMutation } from '../../store/users';

interface AuthContextInterface {
  isAuthenticated: boolean;
  authenticate: () => void;
  signOut: () => void;
}

const AuthContext = createContext<AuthContextInterface>({
  isAuthenticated: false,
  authenticate: () => {},
  signOut: () => {},
});

interface AuthContextProviderProps {
  children: ReactNode;
}

const AuthContextProvider: FC<AuthContextProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  const [authorize] = useGetExternalAuthMutation();

  const authenticate = () => {
    setIsAuthenticated(true);
  };

  const signOut = () => {
    setIsAuthenticated(false);
  };

  useEffect(() => {
    authorize()
      .unwrap()
      .then(() => {
        authenticate();
      })
      .catch((err) => {
        console.error(err);
        signOut();
      });
  }, [authorize]);

  return <AuthContext.Provider value={{ isAuthenticated, authenticate, signOut }}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthContextProvider };
