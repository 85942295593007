import { Col, Grid, H3, Row } from '@rcl/rcl';
import { StagingAggregatorDTO } from 'markets-lib';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { TabWithID, useTabsContext } from '../../context';
import { useGetListQuery } from '../../store/customers';
import { AggregatorsTable } from '../AggregatorsTable';
import { colSpanGeneral } from '../FormLayout';
import { HomePageImage, Message } from '../HomePageImage';
import { Page } from '../Page';
import { ShareParkModal } from '../ShareParkModal';

export const HomePage = () => {
  const history = useHistory();
  const { resetTabs } = useTabsContext<TabWithID>();
  const { t } = useTranslation();

  const { data: contracts, isFetching } = useGetListQuery(null);

  const isModalInitiallyOpened = !!location.pathname.match('/home/share-park');
  const [sharePartnerModalOpen, setSharePartnerModalOpen] = useState(isModalInitiallyOpened);

  const openModal = (id: StagingAggregatorDTO['id']) => {
    history.push(`/home/share-park/${id}`);
    setSharePartnerModalOpen(true);
  };

  const closeModal = () => {
    history.push('/home');
    setSharePartnerModalOpen(false);
  };

  useEffect(() => {
    if (isModalInitiallyOpened) {
      setSharePartnerModalOpen(true);
      return;
    }
    setSharePartnerModalOpen(false);
  }, [isModalInitiallyOpened]);

  useEffect(() => {
    resetTabs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO: Add logic to check the access.
  const isAccessOpen = true;
  const isDone = true; // ? TODO: Check if the data was sent just a moment ago, then say Done.
  const errorMessage = isDone ? (
    <Message header={t('done')} text={t('doneText')} />
  ) : (
    <Message header={t('linkHasExpired')} />
  );

  return (
    <>
      {sharePartnerModalOpen && <ShareParkModal isOpen={sharePartnerModalOpen} handleClose={closeModal} />}
      <Page>
        <Grid>
          <Row>
            <Col span={12}>
              <HomePageImage
                parkType={undefined}
                isFetching={isFetching}
                header={t('welcome')}
                text={t('homePageMessage')}
              />
            </Col>
          </Row>
          {isAccessOpen ? (
            <>
              <Row>
                <Col span={colSpanGeneral}>
                  <H3>{t('homePageList')}</H3>
                  <AggregatorsTable data={contracts || []} isLoading={isFetching} status onShareContract={openModal} />
                </Col>
              </Row>
            </>
          ) : (
            errorMessage
          )}
        </Grid>
      </Page>
    </>
  );
};
