import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { ContractStagingDTO } from 'markets-lib';

import { config } from '../config';
import { prepHeaders } from '../util';

export const contractsApi = createApi({
  reducerPath: 'contracts',
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: config.API_URL,
    prepareHeaders: prepHeaders,
    credentials: 'include',
  }),
  tagTypes: ['Contracts'],
  endpoints: (builder) => ({
    upsert: builder.mutation<ContractStagingDTO, ContractStagingDTO>({
      query: (contract) => ({
        url: '/api/contracts-staging',
        method: 'POST',
        body: contract,
      }),
      invalidatesTags: ['Contracts'],
    }),
    getAll: builder.query<ContractStagingDTO[], void>({
      query: () => '/api/contracts-staging',
      providesTags: ['Contracts'],
    }),
    getById: builder.query<ContractStagingDTO, ContractStagingDTO['id']>({
      query: (id) => `/api/contracts-staging/${id}`,
      providesTags: ['Contracts'],
    }),
    remove: builder.mutation<ContractStagingDTO[], ContractStagingDTO['id']>({
      query: (id) => ({
        url: `/api/contracts-staging/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Contracts'],
    }),
  }),
});

export const {
  endpoints,
  middleware,
  reducer,
  reducerPath,
  useUpsertMutation,
  useRemoveMutation,
  useGetAllQuery,
  useGetByIdQuery,
} = contractsApi;
